.root
{
    input
    {
        width: 100%;
    }
    label
    {
        font-weight: bold;
    }
    :global
    {
        .react-datepicker-wrapper,
        .react-datepicker__input-container
        {
            width: 100%;
        }
    }
}
