.root {
    position: relative;
}

.cover {
    z-index: 999;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.colorBlock {
    padding: 5px;
    border-radius: 5px;
    width: 50px;
    border: solid 1px gray;
}

.picker {
    z-index: 1000;
    position: absolute;
    left: 0;
    top: 100%;
}