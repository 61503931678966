/* ==========================================================================
   OVERRIDES
   Description: If you are planning to overrides default styles
   ========================================================================== */

/* No media query since this is the default in Bootstrap
   -------------------------------------------------------------------------- */
.btn-link {
  color: rgba($secondary, 0.6);
  &:hover,
  &:focus,
  &:active {
    color: rgba($secondary, 0.4);
  }
}

button:disabled {
  cursor: initial;
}
/* Small devices (576px and up)
   -------------------------------------------------------------------------- */

@include media-breakpoint-up(sm) {
}

/* Medium devices (768px and up)
      -------------------------------------------------------------------------- */

@include media-breakpoint-up(md) {
}

/* Large devices (992px and up)
      -------------------------------------------------------------------------- */

@include media-breakpoint-up(lg) {
}

/* Extra large devices (1200px and up)
      -------------------------------------------------------------------------- */

@include media-breakpoint-up(xl) {
}
