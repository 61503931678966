/* ==========================================================================
   VARIABLES
   ========================================================================== */

/* Bootstrap variables
   -------------------------------------------------------------------------- */
$primary: #f4e80b;
$secondary: #34277e;

$pagination-color: $secondary;

$pagination-hover-color: white;
$pagination-hover-bg: $secondary;
$pagination-hover-border-color: $secondary;

$pagination-active-color: white;
$pagination-active-bg: $secondary;
$pagination-active-border-color: $secondary;

/* Colors Social
      -------------------------------------------------------------------------- */

/* Project variables
      -------------------------------------------------------------------------- */
$navbar-height: 75px;
$burger-btn-width: 111px;
