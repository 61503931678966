.panel
{
    position: fixed;
    z-index: 4100;
    top: 0;
    right: 0;
  max-width: 800px;
    display: flex;
    overflow: auto;

    height: 100%;
    padding: 25px;

    animation-name: SlideIn;
    animation-duration: 300ms;

    background-color: #fff;
    //box-shadow: -1px 0 30px rgba(0, 0, 0, 0.2);
}
.modalCloseButton
{
    position: absolute;
    top: 10px;
    right: 10px;

    border: none;
    background-color: transparent;
    box-shadow: none;
}
.panelContent
{
    width: 100%;
    min-width: 600px;

    h2
    {
        margin-bottom: 20px;
    }
}

.form
{
    padding: 25px;
}

.panelPadding
{
    width: 0;

    transition: width 300ms;
}

.overlay
{
    position: fixed;
    z-index: 4000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    animation-name: FadeIn;
    animation-duration: 300ms;

    background-color: rgba(0, 0, 0, .3);
}

@keyframes FadeIn
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}

@keyframes SlideIn
{
    from
    {
        transform: translateX(100%);
    }
    to
    {
        transform: translateX(0);
    }
}
