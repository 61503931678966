.root
{
    position: relative;
}

.redacted
{
    opacity: .2;
    *
    {
        font-family: 'Redacted' !important;

        letter-spacing: -.5px !important;
        word-spacing: 5px !important;

        color: #ababab !important;
    }
}

.overlay
{
    font-size: 30px;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;

    width: 100%;
    height: 100%;

    text-align: center;

    justify-content: center;
    align-items: center;

    .note
    {
        font-size: em(10px);
    }
}

.inputGroup
{
    margin-bottom: 15px;
}

.tree
{
    overflow: hidden;

    height: calc(100vh - 158px - 50px);
    padding: 5px 15px;

    background-color: #ccc;
}

.treeItem
{
    height: 30px;
    margin: 10px 0;
    padding: 5px 15px;

    background-color: #fff;
}
