/* ==========================================================================
   NAVIGATION
   ========================================================================== */

/* No media query since this is the default in Bootstrap
   -------------------------------------------------------------------------- */
.navbar
{
    position: fixed;
    z-index: 1;
    top: 0;

    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: $burger-btn-width;

    outline: none;
    background-color: $primary;
    -webkit-box-shadow: 0 3px 6px -2px rgba(0,0,0,.25);
       -moz-box-shadow: 0 3px 6px -2px rgba(0,0,0,.25);
            box-shadow: 0 3px 6px -2px rgba(0,0,0,.25);


    .navbar-header
    {
        display: flex;

        height: $navbar-height;

        align-items: center;
    }
    .navbar-brand
    {
        height: $navbar-height * .5;
        padding: 0 $spacer;
        img
        {
            width: auto;
            height: 100%;
        }
    }

    .navbar-right
    {
        a
        {
            color: $secondary;

            &:hover,
            &:focus
            {
                opacity: .6;
            }
        }
    }
    .dropdown
    {
        .dropdown-menu
        {
            padding: 0;

            background: white;

            li
            {
                padding: 5px 0;

                &:hover
                {
                    background-color: $secondary;
                }
                a
                {
                    padding: 0 10px;

                    &:hover
                    {
                        color: #fff;
                    }
                }
            }
        }
    }
}
/* Small devices (576px and up)
   -------------------------------------------------------------------------- */

@include media-breakpoint-up(sm)
{
}

/* Medium devices (768px and up)
         -------------------------------------------------------------------------- */

@include media-breakpoint-up(md)
{
}

/* Large devices (992px and up)
         -------------------------------------------------------------------------- */

@include media-breakpoint-up(lg)
{
}

/* Extra large devices (1200px and up)
         -------------------------------------------------------------------------- */

@include media-breakpoint-up(xl)
{
}
