/* ==========================================================================
   TABLE
   ========================================================================== */

/* No media query since this is the default in Bootstrap
   -------------------------------------------------------------------------- */
.table
{
    font-size: 14px;
    thead
    {
        color: #727786;

        // tr
        // {
        //     th
        //     {
        //         padding-top: 15px;
        //         padding-right: 30px;
        //         padding-bottom: 15px;
        //         padding-left: 30px;

        //         border-width: 1px;
        //     }
        // }
    }

    tbody
    {
        tr
        {
            td
            {
                // padding-top: 15px;
                // padding-right: 30px;
                // padding-bottom: 15px;
                // padding-left: 30px;
                vertical-align: middle;

                color: #727786;
                border: none;

                a
                {
                    span
                    {
                        text-decoration: underline;
                    }
                }

                &.multiple-actions
                {
                    text-align: right;
                    a
                    {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    .column-icon
    {
        padding-left: 15px;
    }
    .form-check-input
    {
        position: unset;

        margin-top: 0;
        margin-left: 0;
    }
}

/* Small devices (576px and up)
   -------------------------------------------------------------------------- */

@include media-breakpoint-up(sm)
{
}

/* Medium devices (768px and up)
         -------------------------------------------------------------------------- */

@include media-breakpoint-up(md)
{
    .table
    {
        thead
        {
            tr
            {
                th
                {
                    padding-top: 10px;
                    padding-right: 5px;
                    padding-bottom: 10px;
                    padding-left: 5px;

                    border-bottom-width: 1px;
                }
            }
        }

        tbody
        {
            tr
            {
                td
                {
                    padding-top: 10px;
                    padding-right: 5px;
                    padding-bottom: 10px;
                    padding-left: 5px;
                }
            }
        }
    }
}
