/* ==========================================================================
   BASE
   ========================================================================== */

/* No media query since this is the default in Bootstrap
   -------------------------------------------------------------------------- */

*
{
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}
body
{
    background-color: #e5e9ee;
}
label
{
    font-weight: bold;
}
.table-col-id
{
    display: block;
    overflow: hidden;

    max-width: 150px;

    white-space: pre-wrap;
}
.sortable
{
    cursor: pointer;
}
