.root
{
    .heading
    {
        margin-bottom: 1rem;
    }
    .description
    {
        font-size: .9rem;
    }
}
.listing
{
    font-size: .9rem;
}
