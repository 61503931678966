.contentRoot
{
    padding: 2rem;
    .title
    {
        font-size: 1.8rem;

        margin-bottom: 0;
    }
}
