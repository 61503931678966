.root
{
    display: flex;

    margin-bottom: 1rem;

    flex-wrap: wrap;
    align-items: center;
    > div
    {
        margin-bottom: 1rem;
        &:not(:last-child)
        {
            margin-right: 1rem;
        }
    }
}
