/* ==========================================================================
   VARIABLES
   ========================================================================== */
/* Bootstrap variables
   -------------------------------------------------------------------------- */
/* Colors Social
      -------------------------------------------------------------------------- */
/* Project variables
      -------------------------------------------------------------------------- */
.content {
  margin-top: 75px; }

.title {
  font-size: 1.5rem;
  color: #34277e;
  line-height: 75px;
  margin-bottom: 0; }
