.DropzoneContainer
{
    .Background
    {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .Dropzone
    {
        position: relative;

        overflow: hidden;

        height: 200px;

        border-radius: 5px;
    }
    .DropZoneTextOverlay
    {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;

        width: 100%;
        height: 100%;

        color: white;
        background-color: rgba(0, 0, 0, .2);

        align-items: center;
        justify-content: center;
        cursor: pointer;

        p
        {
            margin-bottom: 0;
            background-color: rgba(0, 0, 0, .5);
            padding: 10px;
            border-radius: 5px;
            font-weight: bold;
        }

        &:hover {
          background-color: rgba(0, 0, 0, .3);
          p {
            transform: scale(1.1);
          }
        }
    }
}
