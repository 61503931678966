/* Position and sizing of burger button */
.bm-burger-button
{
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;

    width: 111px;
    height: $navbar-height;
    margin-bottom: 25px;
    padding-top: 25px;

    text-align: center;

    background: $secondary;

    .bm-icon
    {
        i
        {
            color: #fff;
        }
        span
        {
            text-transform: uppercase;

            color: #fff;
        }
    }
}
.bm-menu
{
    *
    {
        outline: none;
    }
}
/* Color/shape of burger icon bars */
.bm-burger-bars
{
    background: #fff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button
{
    top: 18px !important;

    width: 24px;
    height: 24px;
}

/* Color/shape of close button cross */
.bm-cross
{
    background: none;

    i
    {
        font-size: em(15px);

        color: #727786;
    }
}

/* General sidebar styles */
.bm-menu
{
    padding-top: 53px;

    background: #fff;
    box-shadow: 2px 0 4px 0 rgba(0, 0, 0, .3);
}

.bm-menu-wrap
{
    z-index: 0;

    //width: 230px !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape
{
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list
{
    color: #fff;

    li
    {
        a,
        span
        {
            font-size: em(16px);
            font-weight: 600;

            display: block;

            padding-top: 12.5px;
            padding-bottom: 12.5px;
            padding-left: 15px;

            color: $blue;
            border-bottom: 1px solid #e3e8ed;
        }
        &:first-child
        {
            a,
            span
            {
                border-top: 1px solid #e3e8ed;
            }
        }
        span
        {
            cursor: pointer;

            &:after
            {
                position: absolute;
                top: 20px;
                right: 15px;

                width: 0;
                height: 0;

                content: '';

                border-top: 6px solid #999;
                border-right: 6px solid transparent;
                border-left: 6px solid transparent;
            }

            &.collapsed
            {
                &:after
                {
                    position: absolute;
                    top: 18px;
                    right: 15px;

                    width: 0;
                    height: 0;

                    content: '';

                    border-color: #999 transparent transparent;
                    border-top: 6px solid transparent;
                    border-bottom: 6px solid transparent;
                    border-left: 6px solid #999;
                }
            }
        }

        ul
        {
            padding-left: 25px;

            li
            {
                &.first
                {
                    border-top: none;
                }
            }
        }

        &.first
        {
            border-top: 1px solid #e3e8ed;
        }

        &.active
        {
            &:before
            {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;

                display: block;

                width: 4px;

                content: '';

                background: $secondary;
            }

            a
            {
                color: $secondary;
                background: none;

                &:hover
                {
                    color: $secondary;
                    background: none;
                }
            }
        }

        a
        {
            text-decoration: none;
            &:hover
            {
                color: $primary;
                background: none;

                i
                {
                    color: $secondary;
                }
            }
        }
    }
}
.bm-menu-wrap
{
    top: 0;
    ul
    {
        li
        {
            a
            {
                color: $secondary;
                &:hover
                {
                    color: white;
                    background-color: rgba($secondary, .6);
                }
            }

            &.active
            {
                a
                {
                    color: white;
                    background-color: $secondary;
                    &:hover
                    {
                        color: white;
                        background-color: $secondary;
                    }
                }
            }
        }
    }
}
/* Styling of overlay */
.bm-overlay
{
    z-index: 0;

    background-color: rgba(255, 255, 255, .4) !important;
}
/* Small devices (576px and up)
   -------------------------------------------------------------------------- */

@include media-breakpoint-up(sm)
{
}

/* Medium devices (768px and up)
         -------------------------------------------------------------------------- */

@include media-breakpoint-up(md)
{
}

/* Large devices (992px and up)
         -------------------------------------------------------------------------- */

@include media-breakpoint-up(lg)
{
}

/* Extra large devices (1200px and up)
         -------------------------------------------------------------------------- */

@include media-breakpoint-up(xl)
{
}
