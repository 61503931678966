.root
{
    margin-bottom: 1rem;
    padding: 1rem;

    background-color: white;
    select
    {
        border: 1px solid rgb(97, 97, 97);
        border-radius: 0;
        background-color: transparent;
    }
}
